<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router';
import { OrderBox } from '../../../types/order';
import { computed, useTracker } from '#imports';

interface IProps {
  code: string;
  path?: RouteLocationRaw | undefined;
  isMobile?: boolean;
}

const props = defineProps<IProps>();

const { jitsuEvent, gtagEvent } = useTracker();

const manageOrderList = {
  change_flight: {
    title: 'global.aftersalestitle1',
    subtitle: 'global.aftersalesdesc1',
    imgSource: '/img/icon/change_flight.png',
    event: 'homepage-changeflight-click',
    params: 'change-flight',
    analytic: 'homepage_changeflight_click',
    height: 27,
    width: 30,
    mHeight: 18,
    mWidth: 16,
  },
  add_baggage: {
    title: 'global.aftersalestitle2',
    subtitle: 'global.aftersalesdesc2',
    imgSource: '/img/icon/baggage.png',
    event: 'homepage-addbaggage-click',
    params: 'add-baggage',
    analytic: 'homepage_addbaggage_click',
    height: 30,
    width: 25,
    mHeight: 17,
    mWidth: 14,
  },
  refund: {
    title: 'global.aftersalestitle3',
    subtitle: 'global.aftersalesdesc3',
    imgSource: '/img/icon/cancel.png',
    event: 'homepage-refund-click',
    params: 'refund-request',
    analytic: 'homepage_refund_click',
    height: 25,
    width: 28,
    mHeight: 16,
    mWidth: 18,
  },
  order_list: {
    title: 'global.aftersalestitle4',
    subtitle: 'global.aftersalesdesc4',
    imgSource: '/img/icon/order.png',
    analytic: '',
    event: '',
    params: '',
    height: 25,
    width: 28,
    mHeight: 15,
    mWidth: 18,
  },
  special_request: {
    title: 'bookingform.addspecialrequest',
    subtitle: 'hotel.addspecialreqdesc',
    imgSource: '/img/icon/add.png',
    event: '',
    analytic: '',
    params: '',
    height: 26,
    width: 30,
    mHeight: 16,
    mWidth: 18,
  },
  booking_date: {
    title: 'hotel.changedatehoteltitle',
    subtitle: 'hotel.changehoteldatedesc',
    imgSource: '/img/icon/edit-passenger.png',
    event: '',
    analytic: '',
    params: '',
    height: 24,
    width: 30,
    mHeight: 15,
    mWidth: 18,
  },
  guest_data: {
    title: 'manage.changeguestdata',
    subtitle: 'hotel.changeguestdatadesc',
    imgSource: '/img/icon/cancel.png',
    event: '',
    analytic: '',
    params: '',
    height: 25,
    width: 28,
    mHeight: 16,
    mWidth: 18,
  },
} as OrderBox;

const manageOrderItem = computed(() => manageOrderList[props.code]!);

const onClickManageOrder = (event: string | undefined, analytic: string | undefined, param: string | undefined) => {
  if (event) {
    jitsuEvent('user-click-button', {
      event_name: event,
      object_name: 'manage-booking',
      object_parameter: param ?? '',
    });
  }

  if (analytic) {
    gtagEvent(analytic, {});
  }
};
</script>

<template>
  <NuxtLink
    :to="props.path"
    :class="[
      'order-box bg-white flex items-center justify-between rounded shadow-md w-fit cursor-pointer',
      isMobile ? 'p-15' : 'p-20',
    ]"
    @click="onClickManageOrder(manageOrderItem.event, manageOrderItem.analytic, manageOrderItem.params)"
  >
    <div :class="['order-text flex items-center', isMobile ? 'gap-x-10' : 'gap-x-15']">
      <NuxtImg
        :src="manageOrderItem.imgSource"
        :alt="$t(manageOrderItem.title)"
        format="webp"
        :width="isMobile ? manageOrderItem.mWidth : manageOrderItem.width"
        :height="isMobile ? manageOrderItem.mHeight : manageOrderItem.height"
        loading="lazy"
      />

      <div class="flex flex-col whitespace-nowrap gap-y-3 mr-20">
        <h3
          :class="{ 'text-small': props.isMobile }"
          class="font-bold text-gray-darkest text-left"
        >
          {{ $t(manageOrderItem.title) }}
        </h3>
        <p
          v-if="!isMobile"
          class="text-gray-dark text-left text-small"
        >
          {{ $t(manageOrderItem.subtitle) }}
        </p>
      </div>
    </div>

    <div class="w-fit ml-20">
      <ApzIcon
        :icon="['fas', 'chevron-right']"
        class="text-gray-darkest"
      />
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss">
.order-box {
  &:hover {
    .order-text {
      @apply transform scale-105 duration-200 ease-in-out;
    }
  }
}
</style>
